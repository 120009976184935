import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import CardList from '../components/CardList';
import Card from '../components/Card';
import Helmet from 'react-helmet';
import Container from '../components/Container';
import SEO from '../components/SEO';
import config from '../utils/siteConfig';

const Index = ({ data, pageContext }) => {
  const posts = data.allContentfulPost.edges;
  // const featuredPost = posts[0].node;
  const { currentPage } = pageContext;
  // const isFirstPage = currentPage === 1;
  const isFirstPage = true;

  return (
    <Layout>
      <SEO />
      {!isFirstPage && (
        <Helmet>
          <title>{`${config.siteTitle} - Page ${currentPage}`}</title>
        </Helmet>
      )}
      <Container>
        <div>
          {isFirstPage ? (
            <React.Fragment>
              <h1>Breakfast is Served</h1>
              <strong>(coming soon)</strong>
            </React.Fragment>
          ) : (
            <CardList>
              {posts.map(({ node: post }) => (
                <Card key={post.id} {...post} />
              ))}
            </CardList>
          )}
        </div>
        <br />
        <br />
        <div>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/2iZ7id-lxXo"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          id
          slug
          publishDate(formatString: "MMMM DD, YYYY")
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 80)
            }
          }
        }
      }
    }
  }
`;

export default Index;
